// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AlertBox_AlertboxPaper__RMJw3 {\n  border-radius: 24px !important;\n  margin-top: 66px !important;\n  border: 1px solid #F06601;\n}\n\n.AlertBox_ScrollPaper__-l3IS {\n  align-items: initial !important;\n}\n.AlertBox_Alertbox__9dLdT {\n  max-width: 80%;\n  min-width: 50% !important;\n  margin: 0 auto;\n  max-height: 550px;\n  height: auto !important;\n  height: initial !important;\n}\n\n.AlertBox_AlertActions__MSx7U  {\n  padding: 16px !important;\n  border-top: 1px solid #F06601;\n}\n\n.AlertBox_AlertTitle__k61zO {\n  font-size: 24px !important;\n  border-bottom: 1px solid #F06601;\n  padding: 16px !important;\n}\n\n.AlertBox_AlertBody__UuWay {\n  padding: 16px !important;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/UI/AlertBox/AlertBox.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,+BAA6B;AAC/B;AACA;EACE,cAAc;EACd,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,uBAAwB;EAAxB,0BAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,6BAA6B;AAC/B;;AAEA;EACE,0BAA0B;EAC1B,gCAAgC;EAChC,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".AlertboxPaper {\n  border-radius: 24px !important;\n  margin-top: 66px !important;\n  border: 1px solid #F06601;\n}\n\n.ScrollPaper {\n  align-items: unset !important;\n}\n.Alertbox {\n  max-width: 80%;\n  min-width: 50% !important;\n  margin: 0 auto;\n  max-height: 550px;\n  height: unset !important;\n}\n\n.AlertActions  {\n  padding: 16px !important;\n  border-top: 1px solid #F06601;\n}\n\n.AlertTitle {\n  font-size: 24px !important;\n  border-bottom: 1px solid #F06601;\n  padding: 16px !important;\n}\n\n.AlertBody {\n  padding: 16px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AlertboxPaper": "AlertBox_AlertboxPaper__RMJw3",
	"ScrollPaper": "AlertBox_ScrollPaper__-l3IS",
	"Alertbox": "AlertBox_Alertbox__9dLdT",
	"AlertActions": "AlertBox_AlertActions__MSx7U",
	"AlertTitle": "AlertBox_AlertTitle__k61zO",
	"AlertBody": "AlertBox_AlertBody__UuWay"
};
export default ___CSS_LOADER_EXPORT___;
