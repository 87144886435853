// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VpnStatus_paper__KkoF8 {\n    margin-bottom: 20px;\n    padding: 28px 25px 17px 25px;\n    background-color: #fff;\n    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);\n    border-radius: 20px !important;\n    border: 1px solid #f06601;\n    position: relative;\n  }\n\n  tfoot td > div {\n    flex-wrap: wrap;\n    justify-content: flex-end;\n  }\n  tfoot  {\n    display: table-footer-group !important;\n  }", "",{"version":3,"sources":["webpack://./src/containers/AdminPanel/VpnStatus/VpnStatus.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,sBAAsB;IAEtB,0EAA0E;IAC1E,8BAA8B;IAC9B,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,yBAAyB;EAC3B;EACA;IACE,sCAAsC;EACxC","sourcesContent":[".paper {\n    margin-bottom: 20px;\n    padding: 28px 25px 17px 25px;\n    background-color: #fff;\n    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);\n    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);\n    border-radius: 20px !important;\n    border: 1px solid #f06601;\n    position: relative;\n  }\n\n  tfoot td > div {\n    flex-wrap: wrap;\n    justify-content: flex-end;\n  }\n  tfoot  {\n    display: table-footer-group !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "VpnStatus_paper__KkoF8"
};
export default ___CSS_LOADER_EXPORT___;
