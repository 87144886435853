// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FallbackPage_fallbackContainer__Swrmj {\n    height: 75vh;\n  }\n  \n  .FallbackPage_fallbackGrid__SkrAD {\n    height: 100%;\n  }\n  \n  .FallbackPage_fallbackImage__Z16Up {\n    width: 250px; \n    margin-bottom: 20px;\n  }\n  \n  .FallbackPage_fallbackButton__509bq {\n    margin-top: 20px;\n    background: gray;\n  }\n\n  .FallbackPage_fallbackHeading__ZqhWU{\n    margin-bottom: 15px !important;\n    padding-bottom: 0px !important;\n    margin-left: 7px !important;\n    margin-top: 15px !important;\n    color: #424242 !important;\n  }\n\n  .FallbackPage_fallbackSubHeading__FQdfZ{\n    color: #707070 !important;\n  }\n\n  .FallbackPage_mailLinkSpan__r1HGh a{\n    color: #f06601;\n  }", "",{"version":3,"sources":["webpack://./src/containers/FallbackPage/FallbackPage.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,8BAA8B;IAC9B,8BAA8B;IAC9B,2BAA2B;IAC3B,2BAA2B;IAC3B,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".fallbackContainer {\n    height: 75vh;\n  }\n  \n  .fallbackGrid {\n    height: 100%;\n  }\n  \n  .fallbackImage {\n    width: 250px; \n    margin-bottom: 20px;\n  }\n  \n  .fallbackButton {\n    margin-top: 20px;\n    background: gray;\n  }\n\n  .fallbackHeading{\n    margin-bottom: 15px !important;\n    padding-bottom: 0px !important;\n    margin-left: 7px !important;\n    margin-top: 15px !important;\n    color: #424242 !important;\n  }\n\n  .fallbackSubHeading{\n    color: #707070 !important;\n  }\n\n  .mailLinkSpan a{\n    color: #f06601;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fallbackContainer": "FallbackPage_fallbackContainer__Swrmj",
	"fallbackGrid": "FallbackPage_fallbackGrid__SkrAD",
	"fallbackImage": "FallbackPage_fallbackImage__Z16Up",
	"fallbackButton": "FallbackPage_fallbackButton__509bq",
	"fallbackHeading": "FallbackPage_fallbackHeading__ZqhWU",
	"fallbackSubHeading": "FallbackPage_fallbackSubHeading__FQdfZ",
	"mailLinkSpan": "FallbackPage_mailLinkSpan__r1HGh"
};
export default ___CSS_LOADER_EXPORT___;
