// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox_Checkbox__Q87v3 {\n  display: flex;\n}\n\n.Checkbox_Label__nczem {\n  width: 50%;\n  align-self: center;\n  font-weight: bold;\n}\n.Checkbox_Label__nczem + span {\n  padding: 0px 7px 0px 0px;\n}\n.Checkbox_Checkbox__Q87v3 + span{\n  color : #707070;\n}\n\n.Checkbox_Checkbox__Q87v3 svg {\n  color: #F06601;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/Form/Checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".Checkbox {\n  display: flex;\n}\n\n.Label {\n  width: 50%;\n  align-self: center;\n  font-weight: bold;\n}\n.Label + span {\n  padding: 0px 7px 0px 0px;\n}\n.Checkbox + span{\n  color : #707070;\n}\n\n.Checkbox svg {\n  color: #F06601;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Checkbox": "Checkbox_Checkbox__Q87v3",
	"Label": "Checkbox_Label__nczem"
};
export default ___CSS_LOADER_EXPORT___;
