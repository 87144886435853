// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_Main__-vO1o {\n  width: 100%; /*246px*/\n  padding: 35px;\n}\n\n.Layout_mainContainer__xdMOb {\n  display: flex;\n  background-color: #fef9f5;\n  box-shadow: inset 0px 5px 10px 1px #ccc;\n  min-height: calc(100vh - 101px);\n  position: relative;\n}\n\n@media (max-width: 599.95px) {\n  .Layout_mainContainer__xdMOb nav > div {\n    display: block !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,QAAQ;EACrB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,uCAAuC;EACvC,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".Main {\n  width: 100%; /*246px*/\n  padding: 35px;\n}\n\n.mainContainer {\n  display: flex;\n  background-color: #fef9f5;\n  box-shadow: inset 0px 5px 10px 1px #ccc;\n  min-height: calc(100vh - 101px);\n  position: relative;\n}\n\n@media (max-width: 599.95px) {\n  .mainContainer nav > div {\n    display: block !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": "Layout_Main__-vO1o",
	"mainContainer": "Layout_mainContainer__xdMOb"
};
export default ___CSS_LOADER_EXPORT___;
