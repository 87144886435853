// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_Footer__\\+a-r- {\n  text-align: right;\n  padding: 20px 25px 20px 10px;\n  box-shadow: 0px 0px 11px #e4e4e4 !important;\n  margin-top: 1px;\n  font-size: 12px;\n  line-height: 1.2;\n  vertical-align: middle;\n  padding-left: 5px;\n  color: #707070;\n  font-family: \"Roboto\", sans-serif !important;\n  background-color: #fff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Layout/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,4BAA4B;EAC5B,2CAA2C;EAC3C,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,iBAAiB;EACjB,cAAc;EACd,4CAA4C;EAC5C,sBAAsB;AACxB","sourcesContent":[".Footer {\n  text-align: right;\n  padding: 20px 25px 20px 10px;\n  box-shadow: 0px 0px 11px #e4e4e4 !important;\n  margin-top: 1px;\n  font-size: 12px;\n  line-height: 1.2;\n  vertical-align: middle;\n  padding-left: 5px;\n  color: #707070;\n  font-family: \"Roboto\", sans-serif !important;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "Footer_Footer__+a-r-"
};
export default ___CSS_LOADER_EXPORT___;
