// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_CenterItems__LHzjg {\n  display: flex;\n  align-items: center;\n  align-content: center;\n  height: 100%;\n  flex-direction: column;\n  min-height: 768px;\n  transform: translate(calc(50% - 50px), calc(50% - 50px));\n}\n\n.Loading_LoadingPadding__lNZbM {\n  color: #333;\n}\n\n.Loading_backdroptext__HmpSt {\n  color: #fff;\n  font-size: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Layout/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,sBAAsB;EACtB,iBAAiB;EACjB,wDAAwD;AAC1D;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".CenterItems {\n  display: flex;\n  align-items: center;\n  align-content: center;\n  height: 100%;\n  flex-direction: column;\n  min-height: 768px;\n  transform: translate(calc(50% - 50px), calc(50% - 50px));\n}\n\n.LoadingPadding {\n  color: #333;\n}\n\n.backdroptext {\n  color: #fff;\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CenterItems": "Loading_CenterItems__LHzjg",
	"LoadingPadding": "Loading_LoadingPadding__lNZbM",
	"backdroptext": "Loading_backdroptext__HmpSt"
};
export default ___CSS_LOADER_EXPORT___;
