// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "   .LogoutPage_paper__1v2VH {\n    margin-bottom: 20px;\n    padding: 30px;\n    background-color: #fff;\n    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);\n    border-radius: 20px !important;\n    border: 1px solid #f06601;\n    position: relative;\n    justify-content: center;\n    display: flex;\n    align-items: center;\n    min-height: 100px;\n    flex-direction: column;\n  }\n  .LogoutPage_logoutPage__atu0e {\n    display: flex;\n    width: 100%;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n  }\n  .LogoutPage_RALogoImg__P1cJY{\n    display: block;\n    margin-bottom: 20px;\n  }\n  .LogoutPage_RALogoImg__P1cJY img {\n    width: 170px;\n    height: auto;\n  }\n  \n", "",{"version":3,"sources":["webpack://./src/containers/Auth/LogoutPage/LogoutPage.module.css"],"names":[],"mappings":"GAAG;IACC,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IAEtB,oEAAoE;IACpE,8BAA8B;IAC9B,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,cAAc;IACd,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,YAAY;EACd","sourcesContent":["   .paper {\n    margin-bottom: 20px;\n    padding: 30px;\n    background-color: #fff;\n    -webkit-box-shadow: none !important;\n    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);\n    border-radius: 20px !important;\n    border: 1px solid #f06601;\n    position: relative;\n    justify-content: center;\n    display: flex;\n    align-items: center;\n    min-height: 100px;\n    flex-direction: column;\n  }\n  .logoutPage {\n    display: flex;\n    width: 100%;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n  }\n  .RALogoImg{\n    display: block;\n    margin-bottom: 20px;\n  }\n  .RALogoImg img {\n    width: 170px;\n    height: auto;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "LogoutPage_paper__1v2VH",
	"logoutPage": "LogoutPage_logoutPage__atu0e",
	"RALogoImg": "LogoutPage_RALogoImg__P1cJY"
};
export default ___CSS_LOADER_EXPORT___;
